.App {
  text-align: center;
}

/*html {*/
/*  --s: 50px;*/
/*  --c: #ffffff;*/
/*  --_s: calc(2*var(--s)) calc(2*var(--s));*/
/*  --_g: 35.36% 35.36% at;*/
/*  --_c: #0000 66%, rgba(73, 54, 87, 0.05) 68% 70%,#0000 72%;*/
/*  background:*/
/*          radial-gradient(var(--_g) 100% 25%,var(--_c)) var(--s) var(--s)/var(--_s),*/
/*          radial-gradient(var(--_g) 0 75%,var(--_c)) var(--s) var(--s)/var(--_s),*/
/*          radial-gradient(var(--_g) 100% 25%,var(--_c)) 0 0/var(--_s),*/
/*          radial-gradient(var(--_g) 0 75%,var(--_c)) 0 0/var(--_s),*/
/*          repeating-conic-gradient(var(--c) 0 25%,#0000 0 50%) 0 0/var(--_s),*/
/*          radial-gradient(var(--_c)) 0 calc(var(--s)/2)/var(--s) var(--s) var(--c);*/
/*  background-attachment: fixed;*/
/*}*/

html, body {
  width: 100%;
  height:100%;
}

body {
  /*background: linear-gradient(-15deg, #e4782f, #4936578C);*/
  /*background-size: 400% 400%;*/
  /*animation: gradient 15s ease infinite;*/
  background: #493657FF;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #493657FF, #e4782f);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #493657FF, #e4782f); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.Top {
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
  width: 100%; /* Take up the full width */
}

.image-stack {
  position: relative; /* Establish a stacking context */
  display: flex; /* Use flexbox for centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin: auto; /* For horizontal centering of the .image-stack itself if it's not full width */
}

.image-stack > div {
  position: absolute; /* Position children absolutely within the parent */
}

.image-stack > div:first-child {
  z-index: 1; /* Lower z-index for the back picture */
}

.image-stack > div:last-child {
  z-index: 2; /* Higher z-index for the front picture */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
